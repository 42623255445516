<template>
  <div>
    <div v-if="isLoginFail">
      <!-- 网络错误 -->
      <van-empty image="error" description="登录失败" />
    </div>
    <div v-else>
      <van-loading size="24px" vertical>登录中...</van-loading>
    </div>
  </div>
</template>

<script>

import { getWxOauthUrl } from '@/utils';
import { getOpenId, setOpenId } from '@/utils/auth';
import {getWxOpenId } from '../../api/login'

export default {
  name: 'Login',
  data() {
    return {
      openId: undefined,
      code: undefined,
      redirect: undefined,
      otherQuery: {},
      isLoginFail: false,
      toastInstance: null
    }
  },
  methods: {
    showLoading(msg = '自动登录中') {
      if (!this.toastInstance) {
        this.toastInstance = this.$toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true,
          message: msg,
        });
      }
    },
    hideLoading() {
      if (this.toastInstance) {
        this.toastInstance.clear();
        this.toastInstance = null;
      }
    },
    handleLogin() {
      this.showLoading();
      this.$store.dispatch('loginWithOpenId', this.openId).then(res => {z
        this.$router.push({ path: this.redirect || '/', query: this.otherQuery })
      }).catch(err => {
        console.log(err);
        this.$toast.fail('登录失败3');
        this.isLoginFail = true;
      }).finally(() => {
        this.hideLoading();
      });
    },
    getOtherQuery(query) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== 'redirect') {
          acc[cur] = query[cur]
        }
        return acc
      }, {})
    }
  },
  watch: {
    $route: {
      handler: function(route) {
        const query = route.query
        if (query) {
          this.code = query.code
          this.redirect = query.redirect
          this.otherQuery = this.getOtherQuery(query)
        }
      },
      immediate: true
    }
  },
  created() {
    this.openId = getOpenId();
    if (this.openId) {
      this.handleLogin();
    } else if (this.code) {
      // 通过 code 获取 openid 
      this.showLoading();
      getWxOpenId(this.code).then(res => {
        if (res.status == 200) {
          const openId = res.data;
          setOpenId(openId);
          this.openId = openId;
          this.handleLogin();
        } else {
          this.hideLoading();
          this.$toast.fail('登录失败4');
          this.isLoginFail = true;
        }
      }).catch(err => {
        console.log(err);
        this.hideLoading();
        this.isLoginFail = true
      })
    } else {
      // 获取 code 
      const local = window.location.href;
      let uri = getWxOauthUrl(local);
      window.location.href = uri;
    }
  }
}
</script>